import { Icon } from "@phosphor-icons/react/dist/lib/types";
import {
  ForkKnife,
  Bank,
  GraduationCap,
  FirstAid,
  Barbell,
} from "@phosphor-icons/react/dist/ssr";

export type Map = {
  name: string;
  variant: "beach" | "blue" | "golden" | "nearby";
  icon: Icon | null;
  map: {
    pin: string;
    markers: Array<Array<number>>;
  };
};

export const map: Map[] = [
  // Empreendimentos
  {
    name: "Praia dos Anjos",
    variant: "beach",
    icon: null,
    map: {
      pin: "praia-dos-anjos.svg",
      markers: [[-22.972786563514706, -42.021662813493975]],
    },
  },
  {
    name: "Blue Lake",
    variant: "blue",
    icon: null,
    map: {
      pin: "blue-lake.svg",
      markers: [[-22.94141013516687, -42.08223460344789]],
    },
  },
  {
    name: "Golden Lake",
    variant: "golden",
    icon: null,
    map: {
      pin: "golden-lake.svg",
      markers: [[-22.945366295245414, -42.08912573290548]],
    },
  },
  // Proximidades
  {
    name: "Restaurantes",
    variant: "nearby",
    icon: ForkKnife,
    map: {
      pin: "restaurant.svg",
      markers: [
        [-22.896507431084146, -42.036686037624165],
        [-22.892786246558252, -42.03852518477241],
        [-22.890102770964287, -42.03248033416995],
        [-22.886939864341315, -42.02775964661931],
        [-22.87998121032363, -42.04123506380933],
        [-22.972393135978884, -42.02913722979482],
        [-22.969037331067938, -42.03101377234411],
        [-22.96727633080624, -42.02311064122309],
        [-22.968705068623386, -42.01914103198422],
        [-22.972160558127474, -42.024193261924594],
        [-22.972160558127474, -42.024193261924594],
      ],
    },
  },
  {
    name: "Bancos",
    variant: "nearby",
    icon: Bank,
    map: {
      pin: "bank.svg",
      markers: [
        [-22.888849997779143, -42.04803255481928],
        [-22.890826795233536, -42.04228189907577],
        [-22.889838400105802, -42.041895661003444],
        [-22.890391902264252, -42.03786161891471],
        [-22.887742978627013, -42.03142431770929],
        [-22.967539137533645, -42.027818075542314],
        [-22.96692914996477, -42.027469388393726],
        [-22.966860001402726, -42.02722798959852],
        [-22.96706744698279, -42.02447067891553],
        [-22.96793673928998, -42.022893539939446],
      ],
    },
  },
  {
    name: "Escolas",
    variant: "nearby",
    icon: GraduationCap,
    map: {
      pin: "school.svg",
      markers: [
        [-22.9696357528705, -42.02479254379533],
        [-22.969606118370134, -42.0253182567271],
        [-22.968480002541913, -42.02467452660656],
        [-22.968055237117582, -42.024835459136696],
        [-22.96678093283698, -42.02763568516106],
        [-22.893094173745173, -42.041680006015326],
        [-22.887480087541576, -42.0356718582236],
        [-22.88562185292328, -42.04017796906739],
        [-22.885661390094953, -42.03206696954856],
        [-22.88277514629901, -42.04206624408765],
      ],
    },
  },
  {
    name: "Hospitais",
    variant: "nearby",
    icon: FirstAid,
    map: {
      pin: "hospital.svg",
      markers: [
        [-22.89170977779628, -42.037860542939235],
        [-22.891156281013295, -42.031079919002856],
        [-22.886372393313543, -42.04369702936549],
        [-22.88261633026029, -42.029620797396284],
        [-22.879176476085423, -42.01794782454378],
        [-22.975453408055373, -42.028005186190555],
        [-22.874211336017687, -42.035407763006766],
        [-22.877174995408147, -42.01222362612769],
        [-22.877510499942808, -42.01430230855729],
        [-22.88243113790905, -42.02627369946309],
      ],
    },
  },
  {
    name: "Academias",
    variant: "nearby",
    icon: Barbell,
    map: {
      pin: "gym.svg",
      markers: [
        [-22.882327568711435, -42.02288092201186],
        [-22.881121650179008, -42.01511324522398],
        [-22.883889316136713, -42.03530491333832],
        [-22.884067235674497, -42.035283456324606],
        [-22.88139841939899, -42.04412401664671],
        [-22.970203477441938, -42.02690752028508],
        [-22.97564585237951, -42.025333622938014],
        [-22.966389165109955, -42.02673709163457],
        [-22.96739944350212, -42.02719640869826],
        [-22.976125572849558, -42.02640938791794],
      ],
    },
  },
];
