import { tv } from "tailwind-variants";

export const tag = tv({
  base: "flex flex-col items-center whitespace-nowrap px-2 py-1 uppercase text-residencial-white shadow-sm min-h-[3rem] justify-center",
  variants: {
    color: {
      beach: "bg-residencial-blue-700",
      golden: "bg-residencial-blue-900",
      blue: "bg-residencial-blue-950",
    },
  },
});

export const bullet = tv({
  variants: {
    color: {
      beach: "!bg-residencial-blue-300",
      golden: "!bg-residencial-blue-500",
      blue: "!bg-residencial-orange-700",
    },
  },
});
