import * as Checkbox from "@radix-ui/react-checkbox";
import { tv } from "tailwind-variants";

import { ReactNode, useContext } from "react";
import { MapContext } from "@/contexts/MapContext";

interface ControlProps {
  index: number;
  variant: "beach" | "blue" | "golden" | "nearby";
  children: ReactNode;
}

const checkbox = tv({
  base: "group h-12 border px-4 rounded-md data-[state='checked']:text-white uppercase font-semibold font-uiDisplay flex gap-4 items-center whitespace-nowrap hover:brightness-95 transition-all",
  variants: {
    color: {
      beach:
        "border-residencial-blue-300 text-residencial-blue-300 data-[state='checked']:bg-residencial-blue-300",
      blue: "border-residencial-blue-500 text-residencial-blue-500 data-[state='checked']:bg-residencial-blue-500",
      golden:
        "border-residencial-orange-300 text-residencial-orange-300 data-[state='checked']:bg-residencial-orange-300",
      nearby:
        "border-residencial-blue-700 text-residencial-blue-700 data-[state='checked']:bg-residencial-blue-700",
    },
  },
});

export function Control({ index, variant, children }: ControlProps) {
  const { togglePoint, markedPoints } = useContext(MapContext);

  return (
    <Checkbox.Root
      checked={markedPoints.includes(index)}
      key={index}
      className={checkbox({ color: variant })}
      onCheckedChange={() => togglePoint(index)}
    >
      {children}
    </Checkbox.Root>
  );
}
