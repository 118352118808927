"use client";

import Image from "next/image";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { bullet } from "./variants";
import "swiper/css";
import { Fancybox } from "@/components/Global/Fancybox";
import { Variant } from "@/@types/Variant";

interface SwiperProps {
  images: Array<{
    src: string;
    caption: string;
  }>;
  id: Variant;
}

export function Swiper({ images, id }: SwiperProps) {
  return (
    <Fancybox className="h-full w-full">
      <SwiperContainer
        className="h-full w-full"
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: `[data-pagination='${id}']`,
          bulletClass:
            "block h-4 w-4 rounded-full bg-residencial-white cursor-pointer",
          bulletActiveClass: bullet({ color: id }),
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="relative h-full after:pointer-events-none after:absolute after:inset-0 after:bg-black/10 lg:h-full lg:w-full">
              <a
                href={`/img/desktop/${image.src}`}
                data-fancybox={id}
                className="block h-full w-full"
              >
                <Image
                  className="h-[320px] w-full object-cover md:h-[480px] lg:h-[610px]"
                  src={`/img/mobile/${image.src}`}
                  alt={image.caption}
                  width={425}
                  height={425}
                  quality={100}
                />
              </a>
              <strong className="relative bottom-8 z-10 block w-full select-none bg-residencial-blue-700 py-1 text-center font-uiDisplay font-semibold text-white lg:absolute lg:bottom-12 lg:bg-transparent lg:py-0">
                {image.caption}
              </strong>
            </div>
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </Fancybox>
  );
}
