import { Variant } from "@/@types/Variant";
import Image from "next/image";
import { extraButton, title } from "../variants";
import { Extra } from "./Extra";

interface InfoProps {
  info: {
    description: Array<string>;
    differential: Array<{
      icon: string;
      label: string;
    }>;
    extra?: Array<string>;
  };
  variant: Variant;
}

export function Info({ info, variant }: InfoProps) {
  return (
    <div className="xl:px-20">
      <div>
        <strong className={title({ color: variant })}>O Empreendimento</strong>
        <div className="mt-8 flex flex-col gap-4 font-montserrat">
          {info.description.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <strong className={title({ color: variant })}>Diferenciais</strong>
        <div className="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          {info.differential.map((item) => (
            <div className="flex items-center gap-4">
              <Image src={item.icon} width={32} height={32} alt={item.label} />
              <span className="block max-w-[150px]">{item.label}</span>
            </div>
          ))}
        </div>
        {info.extra && (
          <Extra
            handler={
              <button className={extraButton({ color: variant })}>
                + Diferenciais
              </button>
            }
            extra={info.extra}
          />
        )}
      </div>
    </div>
  );
}
