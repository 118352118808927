import * as Dialog from "@radix-ui/react-dialog";
import { PropsWithChildren } from "react";
import { Menu } from "../Header/Menu";

import volendamLogo from "../../../public/img/logo-volendam-alt.svg";
import Image from "next/image";
import Link from "next/link";

export function MobileMenu({ children }: PropsWithChildren) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content className="group">
          <div className="group-data-[state='open']:animate-left fixed right-0 top-0 z-[999] flex h-fit w-[225px] flex-col items-end rounded-l-[2rem] bg-residencial-orange-500 px-4 pb-8 pt-4">
            <div className="flex items-center gap-12">
              <Image src={volendamLogo} alt="Residence Club" width={80} />
              <Dialog.Trigger asChild>
                <button aria-label="Fechar Menu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="#ffffff"
                    viewBox="0 0 256 256"
                  >
                    <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                  </svg>
                </button>
              </Dialog.Trigger>
            </div>

            <Menu variant="mobile" />

            <div className="mt-12 flex flex-col items-center gap-2 md:justify-self-start">
              <span className="font-montserrat text-sm font-medium text-white">
                Siga a Volendam
              </span>

              <div className="flex items-center gap-4">
                <Link
                  className="flex h-10 w-10 items-end justify-center rounded-full bg-white"
                  href="#"
                  target="_blank"
                  title="Facebook"
                >
                  <svg
                    width="16"
                    height="29"
                    viewBox="0 0 16 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6004 15.8L15.4004 10.7H10.5004V7.4C10.5004 6 11.2004 4.6 13.4004 4.6H15.6004V0.3C15.6004 0.3 13.6004 0 11.6004 0C7.60039 0 4.90039 2.4 4.90039 6.9V10.8H0.400391V15.9H4.90039V28.3C5.80039 28.4 6.70039 28.5 7.70039 28.5C8.60039 28.5 9.60039 28.4 10.5004 28.3V15.8H14.6004Z"
                      fill="#F05823"
                    />
                  </svg>
                </Link>

                <Link
                  className="grid h-10 w-10 place-items-center rounded-full bg-white"
                  href="#"
                  target="_blank"
                  title="Instagram"
                >
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.2 4.6001C15.5 4.6001 15 5.1001 15 5.8001C15 6.5001 15.5 7.0001 16.2 7.0001C16.9 7.0001 17.4 6.5001 17.4 5.8001C17.4 5.1001 16.9 4.6001 16.2 4.6001Z"
                      fill="#F05823"
                    />
                    <path
                      d="M10.8002 6.1001C8.00019 6.1001 5.7002 8.4001 5.7002 11.2001C5.7002 14.0001 8.00019 16.3001 10.8002 16.3001C13.6002 16.3001 15.9002 14.0001 15.9002 11.2001C15.9002 8.4001 13.6002 6.1001 10.8002 6.1001ZM10.8002 14.5001C9.00019 14.5001 7.5002 13.0001 7.5002 11.2001C7.5002 9.4001 9.00019 7.9001 10.8002 7.9001C12.6002 7.9001 14.1002 9.4001 14.1002 11.2001C14.0002 13.1001 12.6002 14.5001 10.8002 14.5001Z"
                      fill="#F05823"
                    />
                    <path
                      d="M14.7998 21.5999H6.4998C3.0998 21.5999 0.299805 18.7999 0.299805 15.3999V7.0999C0.299805 3.6999 3.0998 0.899902 6.4998 0.899902H14.7998C18.1998 0.899902 20.9998 3.6999 20.9998 7.0999V15.3999C21.0998 18.7999 18.2998 21.5999 14.7998 21.5999ZM6.4998 2.7999C4.0998 2.7999 2.1998 4.6999 2.1998 7.0999V15.3999C2.1998 17.7999 4.0998 19.6999 6.4998 19.6999H14.7998C17.1998 19.6999 19.0998 17.7999 19.0998 15.3999V7.0999C19.0998 4.6999 17.1998 2.7999 14.7998 2.7999H6.4998Z"
                      fill="#F05823"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
