"use client";

import { BuildingContext } from "@/contexts/BuildingContext";
import { useContext } from "react";
import { tab } from "../variants";
import { Variant } from "@/@types/Variant";

interface TabsProps {
  variant: Variant;
}

const tabs = [
  {
    label: "Informação",
    icon: () => (
      <svg
        width="10"
        height="23"
        viewBox="0 0 10 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_95_3936)">
          <path
            d="M7.56298 0C7.68988 0.0257848 7.79141 0.0515695 7.91831 0.0773543C9.49191 0.438341 10.431 2.16592 9.79648 3.63565C9.39039 4.58969 8.65435 5.15695 7.63912 5.31166C6.49699 5.46637 5.53252 5.10538 4.82186 4.15135C3.93354 2.93946 4.31425 1.21188 5.68481 0.412556C6.01476 0.206278 6.42085 0.128924 6.80156 0C7.05537 0 7.30917 0 7.56298 0Z"
            fill="white"
          />
          <path
            d="M9.67005 20.5247C9.56853 20.9373 9.49239 21.3241 9.39086 21.7108C9.36548 21.7624 9.31472 21.8398 9.26396 21.8655C8.4264 22.175 7.58883 22.5617 6.72589 22.7938C5.50761 23.129 4.28934 23.1548 3.12183 22.5875C2.03046 22.0718 1.49746 21.1694 1.49746 19.9317C1.49746 18.9261 1.72589 17.972 2.00508 17.018C2.43655 15.4709 2.84264 13.9496 3.22335 12.4025C3.35025 11.8868 3.40102 11.3454 3.29949 10.8039C3.22335 10.3398 2.94416 10.0561 2.48731 9.95299C1.87817 9.82407 1.26904 9.87564 0.659898 10.0819C0.482233 10.0819 0.253807 10.1593 0 10.2366C0.101523 9.79828 0.203046 9.41151 0.304569 8.99896C0.304569 8.94739 0.380711 8.89582 0.431472 8.89582C1.4467 8.50904 2.46193 8.0707 3.52792 7.86443C4.56853 7.68393 5.60914 7.70972 6.59898 8.19963C7.63959 8.71532 8.17259 9.82407 8.12183 10.9844C8.07107 12.5573 7.51269 14.0012 7.13198 15.4967C6.87817 16.4765 6.62437 17.4564 6.37056 18.4362C6.26904 18.9261 6.19289 19.416 6.29442 19.9317C6.42132 20.5247 6.67513 20.7568 7.25888 20.8599C8.0203 20.9889 8.73096 20.8342 9.41624 20.5763C9.467 20.5505 9.51777 20.5247 9.56853 20.5247C9.61929 20.5247 9.64467 20.5247 9.67005 20.5247Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_95_3936">
            <rect width="10" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    label: "Fotos",
    icon: () => (
      <svg
        width="26"
        height="23"
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-0.000210013 15.9854C0.523984 14.2992 1.04818 12.613 1.57237 10.893C1.92183 9.78014 2.23635 8.70096 2.58581 7.58806C2.62076 7.52061 2.62076 7.48688 2.69065 7.41943C2.69065 7.52061 2.69065 7.58806 2.69065 7.68923C2.69065 9.88131 2.69065 12.0734 2.69065 14.2655C2.69065 15.8505 3.42452 17.0646 4.85732 17.8403C5.48635 18.1775 6.15033 18.3124 6.84925 18.3124C9.71484 18.3124 12.5804 18.3124 15.446 18.3124C17.6826 18.3124 19.9191 18.3124 22.1557 18.3124C22.2605 18.3124 22.3304 18.3124 22.4702 18.3124C22.4353 18.3798 22.4353 18.4473 22.4003 18.5147C22.0858 19.459 21.8062 20.3696 21.4917 21.3139C21.0724 22.5617 19.8493 23.2361 18.5213 22.8989C15.446 22.1232 12.3708 21.3139 9.26054 20.5045C6.81431 19.8637 4.40302 19.2567 1.95678 18.6159C0.873446 18.3461 0.209467 17.7054 -0.000210013 16.6599C-0.000210013 16.6262 -0.0351562 16.6262 -0.0351562 16.5925C-0.000210013 16.3901 -0.000210013 16.1878 -0.000210013 15.9854Z"
          fill="white"
        />
        <path
          d="M15.1663 0C17.8222 0 20.4781 0 23.169 0C24.5319 0 25.6152 0.77566 25.8948 1.98974C25.9647 2.22581 25.9647 2.46188 25.9647 2.69795C25.9647 6.47507 25.9647 10.2185 25.9647 13.9956C25.9647 15.5806 24.8115 16.7273 23.169 16.7273C17.8222 16.7273 12.5104 16.7273 7.1636 16.7273C5.52113 16.7273 4.33296 15.6144 4.33296 13.9956C4.33296 10.9267 4.33296 7.8915 4.33296 4.82258C4.33296 3.97947 4.29801 3.13636 4.3679 2.29326C4.47274 1.04545 5.59102 0.0337243 6.91898 0C9.40016 0 11.9163 0 14.4324 0C14.677 0 14.9217 0 15.1663 0ZM13.4539 10.8592C13.5588 10.7581 13.5937 10.6906 13.6636 10.6232C14.9217 9.17302 16.1448 7.72287 17.4028 6.30645C18.2765 5.29472 19.5695 5.29472 20.4781 6.30645C21.4916 7.45308 22.505 8.59971 23.5184 9.74633C23.5883 9.81378 23.6582 9.91496 23.798 10.0499C23.798 9.91496 23.798 9.81378 23.798 9.74633C23.798 7.41935 23.798 5.1261 23.798 2.79912C23.798 2.29326 23.6233 2.12463 23.0641 2.12463C17.7873 2.12463 12.4754 2.12463 7.19855 2.12463C6.67435 2.12463 6.49962 2.29326 6.49962 2.79912C6.49962 5.93548 6.49962 9.07185 6.49962 12.2082C6.49962 12.2757 6.49962 12.3768 6.49962 12.478C6.60446 12.3768 6.67435 12.3431 6.7093 12.2757C7.58296 11.4326 8.49156 10.5557 9.36521 9.71261C10.2738 8.83578 11.3921 8.83578 12.3007 9.71261C12.6851 10.0836 13.0695 10.4545 13.4539 10.8592Z"
          fill="white"
        />
        <path
          d="M9.74967 7.31805C8.5615 7.31805 7.58301 6.37377 7.58301 5.22714C7.58301 4.08051 8.5615 3.13623 9.74967 3.13623C10.9378 3.13623 11.9163 4.08051 11.9163 5.22714C11.9163 6.37377 10.9378 7.31805 9.74967 7.31805Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    label: "Tour",
    icon: () => (
      <svg
        width="24"
        height="21"
        viewBox="0 0 24 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 13.2222C0.604534 13.2222 1.23929 13.2222 1.84383 13.2222C1.96474 13.6709 2.29723 13.97 2.65995 14.2393C3.44584 14.8376 4.38287 15.1666 5.3199 15.4358C6.77078 15.8546 8.25189 16.094 9.733 16.1837C9.79345 16.1837 9.88413 16.1837 10.005 16.1837C9.49118 15.6752 9.00756 15.1965 8.52393 14.7478C8.97733 14.2991 9.43073 13.8504 9.8539 13.4316C11.1234 14.688 12.3929 15.9444 13.6625 17.2008C12.3929 18.4871 11.1234 19.7435 9.8539 20.9999C9.46096 20.6111 9.00756 20.1623 8.55416 19.7136C9.06801 19.2051 9.61209 18.6666 10.1864 18.0982C9.64232 18.0384 9.15869 18.0085 8.64484 17.9487C6.49874 17.7093 4.38287 17.2905 2.41814 16.2735C1.75315 15.9444 1.14861 15.5256 0.664987 14.9273C0.302267 14.5085 0.120907 14.0299 0 13.5213C0 13.4316 0 13.3418 0 13.2222Z"
          fill="white"
        />
        <path
          d="M-4.67356e-05 1.85449C2.05537 1.85449 4.14101 1.85449 6.22665 1.85449C6.22665 2.30321 6.22665 2.72201 6.22665 3.17073C6.22665 3.23056 6.1662 3.29039 6.10575 3.35022C5.50121 3.97842 4.89668 4.60663 4.26192 5.23483C4.20147 5.29466 4.11079 5.38441 4.0201 5.47415C4.11079 5.53398 4.17124 5.56389 4.23169 5.62372C4.68509 5.95278 5.16872 6.28184 5.62212 6.64082C7.01255 7.80748 6.6196 9.2733 5.83371 10.2306C5.2594 10.9485 4.44328 11.3075 3.50625 11.3075C2.32741 11.3075 1.14857 11.3075 -0.0302734 11.3075C-0.0302734 10.6793 -0.0302734 10.081 -0.0302734 9.45278C1.14857 9.45278 2.32741 9.45278 3.47602 9.45278C3.86897 9.45278 4.20147 9.30321 4.41305 9.00407C4.71532 8.58526 4.68509 8.34595 4.32237 7.98697C4.26192 7.92714 4.23169 7.89723 4.17124 7.8374C3.53648 7.44851 2.93194 7.05962 2.29718 6.67073C2.05537 6.52116 1.72288 6.49124 1.60197 6.28184C1.48106 6.07244 1.57174 5.74338 1.57174 5.47415C1.57174 5.38441 1.6322 5.26475 1.69265 5.20492C2.11582 4.7562 2.539 4.3374 2.96217 3.88868C3.02262 3.82885 3.05285 3.79894 3.08308 3.73911C3.05285 3.73911 3.02262 3.70919 3.02262 3.70919C1.99492 3.70919 0.997434 3.70919 -0.0302734 3.70919C-4.67356e-05 3.08099 -4.67356e-05 2.4827 -4.67356e-05 1.85449Z"
          fill="white"
        />
        <path
          d="M22.1259 6.58135C22.1259 7.20955 22.1259 7.83776 22.1259 8.49588C22.1259 10.0813 20.8866 11.3078 19.2846 11.3378C18.68 11.3378 18.0755 11.3378 17.5314 11.0685C16.5037 10.56 15.9294 9.72237 15.8992 8.55571C15.8689 7.26938 15.8992 5.95314 15.8992 4.66682C15.8992 3.11126 17.1385 1.88477 18.7103 1.88477C19.1334 1.88477 19.5868 1.88477 20.01 1.97451C21.2493 2.24374 22.1259 3.38049 22.1259 4.6369C22.1259 5.26511 22.1259 5.92323 22.1259 6.58135ZM20.2518 6.58135C20.2518 5.92323 20.2518 5.29502 20.2518 4.6369C20.2518 4.09844 19.8589 3.70955 19.3148 3.70955C19.1032 3.70955 18.9218 3.70955 18.7103 3.70955C18.1662 3.70955 17.7732 4.09844 17.7732 4.6369C17.7732 5.92323 17.7732 7.23947 17.7732 8.52579C17.7732 9.06425 18.1662 9.45314 18.7103 9.45314C18.8916 9.45314 19.073 9.45314 19.2543 9.45314C19.8589 9.45314 20.2518 9.06425 20.2518 8.43605C20.2518 7.83776 20.2518 7.20955 20.2518 6.58135Z"
          fill="white"
        />
        <path
          d="M12.0604 1.40576C12.544 1.85448 12.9974 2.27328 13.4206 2.69209C12.5743 3.64935 11.7279 4.5767 10.8816 5.53397C10.8816 5.56388 10.9118 5.56388 10.9118 5.5938C11.2141 5.5938 11.5163 5.5938 11.8186 5.5938C13.4508 5.5938 14.6901 6.82029 14.6901 8.43568C14.6901 10.0511 13.4508 11.3075 11.8488 11.3075C11.2745 11.3075 10.67 11.3374 10.0957 11.2476C8.7657 11.0981 7.738 9.90149 7.70777 8.58525C7.70777 8.19636 7.67754 7.80747 7.70777 7.41858C7.76822 7.05961 7.82868 6.70063 8.04026 6.40149C8.46344 5.71345 8.94706 4.99551 9.46092 4.3673C10.277 3.38012 11.1536 2.42286 12.0604 1.40576ZM11.1838 7.47841C10.9723 7.47841 10.7304 7.47841 10.5189 7.47841C9.97477 7.47841 9.58183 7.8673 9.58183 8.40576C9.5516 9.00405 9.91432 9.45277 10.4886 9.48269C10.9723 9.5126 11.4559 9.5126 11.9395 9.48269C12.4836 9.45277 12.8463 9.03397 12.8463 8.43568C12.8463 7.89721 12.4534 7.50833 11.9093 7.47841C11.6372 7.47841 11.4257 7.47841 11.1838 7.47841Z"
          fill="white"
        />
        <path
          d="M22.1565 13.2222C22.7912 13.2222 23.3958 13.2222 24.0305 13.2222C24.0608 13.7307 23.9701 14.2093 23.7283 14.6581C23.426 15.1965 22.9424 15.5854 22.4285 15.9444C21.1892 16.7521 19.7988 17.2008 18.3781 17.4999C17.3807 17.7093 16.3832 17.8589 15.3555 18.0384C15.3555 17.3803 15.3555 16.7521 15.3555 16.1239C15.7484 16.064 16.1414 16.0341 16.5343 15.9444C18.0154 15.7051 19.4965 15.376 20.8567 14.688C21.1288 14.5384 21.4008 14.3589 21.6426 14.1495C21.8844 13.9401 22.096 13.641 22.1565 13.2222Z"
          fill="white"
        />
        <path
          d="M24 0.92735C24 1.4359 23.5769 1.8547 23.063 1.8547C22.5492 1.8547 22.126 1.4359 22.126 0.92735C22.126 0.418803 22.5492 0 23.063 0C23.6071 0 24.0303 0.418803 24 0.92735Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    label: "Saiba mais",
    icon: () => (
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_95_4640)">
          <path
            d="M24.0002 13.8615C23.9356 13.8313 23.871 13.8131 23.8123 13.7768C21.2402 12.1016 18.6682 10.4264 16.0961 8.75121C16.0433 8.71492 15.9963 8.67864 15.9141 8.62421C18.6212 6.79176 21.3107 4.96537 24.0002 3.14502C24.0002 6.71314 24.0002 10.2873 24.0002 13.8615Z"
            fill="white"
          />
          <path
            d="M12.0085 0C15.4673 0 18.9319 0 22.3907 0C23.1189 0 23.6767 0.387051 23.894 1.0402C23.9527 1.21558 23.9469 1.33049 23.7648 1.45144C20.5116 3.64674 17.2642 5.85414 14.0168 8.0555C13.4003 8.47278 12.7778 8.89007 12.1612 9.31946C12.0379 9.40413 11.9557 9.40413 11.8324 9.31946C7.95665 6.68872 4.08095 4.05194 0.199372 1.42725C0.0701817 1.34258 0.0466926 1.26396 0.0877986 1.11882C0.24635 0.5201 0.763111 0.0665244 1.36796 0.0120953C1.47953 0 1.58523 0 1.6968 0C5.13209 0 8.57324 0 12.0085 0Z"
            fill="white"
          />
          <path
            d="M23.9469 15.6996C23.7648 16.5402 23.2246 16.9998 22.4142 16.9998C15.4732 16.9998 8.53802 16.9998 1.59698 16.9998C0.880566 16.9998 0.334444 16.6128 0.111298 15.9657C0.0467025 15.7842 0.0643193 15.6754 0.24636 15.5605C2.0374 14.4054 3.82845 13.2382 5.61362 12.071C6.83506 11.2727 8.05649 10.4804 9.27205 9.68214C9.42473 9.58537 9.51869 9.59142 9.65962 9.68818C10.2468 10.0994 10.8517 10.4865 11.433 10.9038C11.8147 11.1759 12.173 11.182 12.5547 10.9098C13.1595 10.4804 13.782 10.0752 14.3868 9.65794C14.4925 9.58537 14.5688 9.58537 14.6745 9.65794C17.7281 11.6537 20.7817 13.6434 23.8353 15.633C23.8764 15.6451 23.9058 15.6693 23.9469 15.6996Z"
            fill="white"
          />
          <path
            d="M0 13.8914C0 10.287 0 6.73704 0 3.13867C2.70712 4.97716 5.38488 6.79146 8.08613 8.62391C5.38488 10.3838 2.71299 12.1255 0 13.8914Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_95_4640">
            <rect width="24" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export function Tabs({ variant }: TabsProps) {
  const { changeCurrentTab, currentTab } = useContext(BuildingContext);
  return (
    <nav
      data-state={!!currentTab}
      className="mt-12 flex h-12 items-center justify-between gap-4 pb-12 pt-4 font-uiDisplay font-medium data-[state='true']:border-b-2 lg:mt-10 lg:py-0"
    >
      {tabs
        .filter((item) => {
          if (variant != "blue") return true;
          return item.label != "Tour";
        })
        .map(({ label, icon: Icon }, index) => (
          <button
            aria-label={`${label} ${variant}`}
            data-current={currentTab == label}
            key={index}
            onClick={() => changeCurrentTab(label)}
            className={tab({ color: variant })}
          >
            <span className="lg:hidden">
              <Icon />
            </span>
            <span className="hidden lg:block">{label}</span>
          </button>
        ))}
    </nav>
  );
}
