import { tv } from "tailwind-variants";

export const size = tv({
  base: "mt-8 flex px-2 w-full items-center justify-center gap-2 rounded-2xl border-2 py-5 lg:mt-16",
  variants: {
    color: {
      beach: "border-residencial-blue-300 py-5 text-residencial-blue-700",
      golden: "border-residencial-blue-500 py-5 text-residencial-blue-900",
      blue: "border-residencial-orange-700 py-5 text-residencial-blue-950",
    },
  },
});

export const address = tv({
  base: "flex items-center gap-2 justify-center",
  variants: {
    color: {
      beach: "text-residencial-blue-700",
      golden: "text-residencial-blue-900",
      blue: "text-residencial-blue-950",
    },
  },
});

export const icon = tv({
  variants: {
    color: {
      beach: "fill-residencial-blue-300",
      golden: "fill-residencial-blue-300",
      blue: "fill-residencial-orange-700",
    },
  },
});

export const waze = tv({
  base: "block rounded-full py-1 text-center uppercase text-white transition-all hover:brightness-95",
  variants: {
    color: {
      beach: "bg-residencial-blue-300",
      golden: "bg-residencial-blue-500",
      blue: "bg-residencial-orange-700",
    },
  },
});

export const tab = tv({
  base: "grid h-12 w-14 place-items-center rounded-md uppercase lg:h-auto lg:w-auto lg:bg-transparent lg:decoration-2 lg:underline-offset-2 lg:data-[current='true']:underline lg:data-[current='true']:bg-transparent",
  variants: {
    color: {
      beach:
        "text-black bg-residencial-blue-300 data-[current='true']:bg-residencial-blue-700",
      golden:
        "bg-residencial-blue-500 text-residencial-blue-900 data-[current='true']:bg-residencial-blue-900",
      blue: "bg-residencial-orange-700 text-residencial-blue-950 data-[current='true']:bg-residencial-blue-950",
    },
  },
});

export const extraIcon = tv({
  base: "w-10 h-10",
  variants: {
    color: {
      beach: "fill-residencial-blue-300",
      golden: "fill-residencial-blue-500",
      blue: "fill-residencial-orange-700",
    },
  },
});
