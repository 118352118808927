"use client";

import { ReactNode } from "react";
import * as Dialog from "@radix-ui/react-dialog";

interface ExtraProps {
  handler: ReactNode;
  extra: Array<string>;
}

export function Extra({ handler, extra }: ExtraProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{handler}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[9999] bg-[rgba(0,0,0,0.6)]" />
        <Dialog.Content className="policy fixed left-1/2 top-1/2 z-[9999] flex h-[75vh] w-[95%] max-w-[1024px] -translate-x-1/2 -translate-y-1/2 flex-col overflow-auto rounded-xl bg-white lg:aspect-video">
          <div className="sticky top-0 flex justify-end bg-white pr-6 pt-6">
            <Dialog.Close asChild>
              <button aria-label="Fechar Política de Privacidade" className="">
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                </svg>
              </button>
            </Dialog.Close>
          </div>
          <div className="grid grid-cols-4 gap-4 p-4 md:p-8">
            {extra.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
