"use client";

import "leaflet/dist/leaflet.css";

import { Markers } from "./Markers";
import { MapContainer, TileLayer } from "react-leaflet";
import { memo, useContext } from "react";
import { MapContext } from "@/contexts/MapContext";

const Map = memo(() => {
  const { render } = useContext(MapContext);

  if (!render) {
    return null;
  }

  return (
    <MapContainer
      center={[-22.972786563514706, -42.021662813493975]}
      zoom={14}
      scrollWheelZoom={false}
      className="h-full w-full"
    >
      <TileLayer
        attribution=""
        url={`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=455e47bf-4d41-4827-9f6b-465d6f72d82a`}
      />
      <Markers />
    </MapContainer>
  );
});

export default Map;
