"use client";

import { useEffect, useRef } from "react";

export function Video() {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.65;
    }
  }, []);

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      muted
      preload="auto"
      loop
      src="/video/banner.webm"
      className="banner-mask absolute -z-10 h-screen w-screen object-cover object-center"
    />
  );
}
