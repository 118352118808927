import Link from "next/link";
import { ComponentProps } from "react";
import { tv } from "tailwind-variants";

interface MenuProps extends ComponentProps<"nav"> {
  isFloating?: boolean;
  variant: "desktop" | "mobile";
}

const nav = tv({
  base: "h-full items-center gap-10 font-montserrat font-medium text-white",
  variants: {
    device: {
      desktop: "hidden lg:flex",
      mobile: "flex flex-col mt-12",
    },
  },
});

export function Menu({ isFloating, variant, ...props }: MenuProps) {
  return (
    <nav className={nav({ device: variant })} {...props}>
      <Link href="#praia-dos-anjos">Praia dos Anjos</Link>
      <Link href="#golden-lake">Golden Lake</Link>
      <Link href="#blue-lake">Blue Lake</Link>
      <Link href="#localizacao">Localização</Link>
      <Link
        href="#contato"
        data-variant={variant}
        data-floating={isFloating}
        className="rounded-md bg-residencial-blue-900 px-2 py-1 bg-blend-multiply data-[floating='true']:bg-[#E21E05] data-[variant='mobile']:bg-[#E21E05]"
      >
        Saiba mais
      </Link>
    </nav>
  );
}
