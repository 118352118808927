import * as RadixSelect from "@radix-ui/react-select";
import { forwardRef } from "react";

interface SelectProps {
  options: Array<{
    label: string;
    value: string;
  }>;
  value: string;
  hasError: boolean;
  onChange: (value: string) => void;
}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  ({ options, hasError, value, onChange }, ref) => {
    return (
      <RadixSelect.Root value={value} onValueChange={onChange}>
        <RadixSelect.Trigger
          title="Empreendimento"
          data-error={hasError}
          ref={ref}
          className="group flex h-10 w-full items-center justify-between rounded-sm bg-white px-4 text-left outline-none focus:ring-2 focus:ring-residencial-teal data-[error=true]:ring-2 data-[error=true]:ring-red-500"
        >
          <RadixSelect.Value placeholder="Empreendimento" />
          <RadixSelect.Icon>
            <svg
              className="transition-all group-data-[state=open]:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
            </svg>
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Portal>
          <RadixSelect.Content
            position="popper"
            className="w-[--radix-select-trigger-width] bg-white"
          >
            <RadixSelect.Viewport>
              {options.map(({ value, label }, index) => (
                <RadixSelect.Item
                  value={value}
                  key={index}
                  className="flex h-10 select-none items-center px-4 outline-none hover:bg-zinc-100"
                >
                  <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                </RadixSelect.Item>
              ))}
            </RadixSelect.Viewport>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    );
  },
);
