"use client";

import { Variant } from "@/@types/Variant";
import { Navigation } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { arrow, title } from "./variants";

interface TourProps {
  tour: Array<{
    description: string;
    iframe: string;
  }>;
  variant: Variant;
}

export function Tour({ tour, variant }: TourProps) {
  const randomId = new Date().getTime();

  return (
    <div className="grid grid-cols-12 items-center gap-y-4">
      <button
        data-prev={randomId}
        className="col-start-5 row-start-2 grid h-fit place-items-center md:col-start-1 md:row-start-1"
        aria-label="Tour anterior"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="#000000"
          viewBox="0 0 256 256"
          className={arrow({ color: variant })}
        >
          <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
        </svg>
      </button>
      <div className="col-span-12 md:col-span-10">
        <Swiper
          modules={[Navigation]}
          navigation={{
            prevEl: `[data-prev='${randomId}']`,
            nextEl: `[data-next='${randomId}']`,
          }}
        >
          {tour.map((item, index) => (
            <SwiperSlide key={index}>
              <div>
                <strong className={title({ color: variant })}>
                  {item.description}
                </strong>
                <iframe
                  className="mt-4 block h-[320px] w-full rounded-2xl md:h-[400px]"
                  src={item.iframe}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        data-next={randomId}
        className="col-start-8 row-start-2 grid h-fit place-items-center md:col-start-12 md:row-start-1"
        aria-label="Próximo tour"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="#000000"
          viewBox="0 0 256 256"
          className={arrow({ color: variant })}
        >
          <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
        </svg>
      </button>
    </div>
  );
}
