"use client";

import Image from "next/image";

import volendamLogo from "../../../public/img/logo-volendam-alt.svg";
import residenceClubLogo from "../../../public/img/residence-club-logo-alt.svg";
import { useEffect, useState } from "react";
import { Menu } from "./Menu";
import { MobileMenu } from "../Global/MobileMenu";

export function Header() {
  const [isHeaderFloating, setIsHeaderFloating] = useState(false);

  useEffect(() => {
    function verifyScroll() {
      if (window.scrollY >= window.innerHeight) {
        setIsHeaderFloating(true);
      } else {
        setIsHeaderFloating(false);
      }
    }

    window.addEventListener("scroll", verifyScroll);

    return () => {
      window.removeEventListener("scroll", verifyScroll);
    };
  }, []);

  return (
    <header
      data-floating={isHeaderFloating}
      className="absolute left-0 top-0 z-[999] w-full px-4 py-4 data-[floating='true']:fixed data-[floating='true']:animate-down data-[floating='true']:bg-residencial-orange-500 data-[floating='true']:shadow-md md:px-8"
    >
      <div className="mx-auto flex max-w-screen-2xl items-center justify-between">
        <Image
          src={residenceClubLogo}
          alt="Residence Club"
          className="lg:w-28"
        />
        <Menu variant="desktop" isFloating={isHeaderFloating} />
        <Image src={volendamLogo} alt="Volendam" className="lg:w-28" />
        <MobileMenu>
          <button aria-label="Abrir menu Mobile" className="lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#ffffff"
              viewBox="0 0 256 256"
            >
              <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
            </svg>
          </button>
        </MobileMenu>
      </div>
    </header>
  );
}
