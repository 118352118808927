"use client";

import * as ScrollArea from "@radix-ui/react-scroll-area";

import { map } from "@/mocks/map";
import { Control } from "./Control";
import { useContext, useEffect, useRef } from "react";
import { MapContext } from "@/contexts/MapContext";

export function Controls() {
  const controlsRef = useRef<HTMLDivElement>(null);
  const { renderMap } = useContext(MapContext);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            renderMap();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 },
    );

    if (controlsRef.current) {
      observer.observe(controlsRef.current);
    }
  }, []);

  return (
    <ScrollArea.Root
      ref={controlsRef}
      className="mx-auto max-w-screen-2xl px-4 pt-8 md:px-8"
      type="auto"
    >
      <ScrollArea.Viewport>
        <div className="flex justify-between gap-8">
          {map.map(({ name, variant, icon: Icon }, index) => (
            <Control key={index} index={index} variant={variant}>
              {Icon && (
                <Icon className="h-6 w-6 group-data-[status='checked']:fill-white" />
              )}
              {name}
            </Control>
          ))}
        </div>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="mx-4 flex h-1 translate-y-3 rounded-full bg-zinc-200 md:mx-8"
        orientation="horizontal"
      >
        <ScrollArea.Thumb className="relative rounded-full bg-residencial-blue-700" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}
