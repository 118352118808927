import { tv } from "tailwind-variants";

export const title = tv({
  base: "block uppercase",
  variants: {
    color: {
      beach: "text-residencial-blue-700",
      golden: "text-residencial-blue-500",
      blue: "text-residencial-blue-950",
    },
    align: {
      centered: "text-center ",
    },
  },
});

export const arrow = tv({
  variants: {
    color: {
      beach: "fill-residencial-blue-700",
      golden: "fill-residencial-blue-500",
      blue: "fill-residencial-blue-950",
    },
  },
});

export const extraButton = tv({
  base: "block w-fit px-8 mx-auto mt-12 rounded-full py-1 text-center uppercase text-white transition-all hover:brightness-95",
  variants: {
    color: {
      beach: "bg-residencial-blue-700",
      golden: "bg-residencial-blue-500",
      blue: "bg-residencial-blue-950",
    },
  },
});
