"use client";

import { BuildingContext } from "@/contexts/BuildingContext";
import { useContext } from "react";
import { Info } from "./Info";
import { Photos } from "./Photos";
import { Panels } from "@/mocks/buildings";
import { Variant } from "@/@types/Variant";
import { KnowMore } from "./KnowMore";
import { Tour } from "./Tour";

interface PanelsProps {
  panels: Panels;
  variant: Variant;
}

export function Panels({ panels, variant }: PanelsProps) {
  const { currentTab } = useContext(BuildingContext);

  return (
    <div
      data-render={!!currentTab}
      className="group relative -top-16 max-h-0 overflow-hidden rounded-b-2xl bg-white transition-all duration-500 data-[render='true']:max-h-[1000px] "
    >
      <div className="mt-24 h-[250px] px-4 pb-12 group-data-[render='true']:h-auto md:px-8 xl:px-20">
        {currentTab == "Informação" && (
          <Info info={panels.info} variant={variant} />
        )}
        {currentTab == "Fotos" && (
          <Photos images={panels.images} variant={variant} />
        )}
        {currentTab == "Tour" && <Tour tour={panels.tour} variant={variant} />}
        {currentTab == "Saiba mais" && (
          <KnowMore buildingId={panels.buildingId} variant={variant} />
        )}
      </div>
    </div>
  );
}
