import { Fancybox } from "@/components/Global/Fancybox";
import Image from "next/image";
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/grid";
import { Variant } from "@/@types/Variant";
import { arrow, title } from "./variants";

interface PhotosProps {
  variant: Variant;
  images: Array<{
    src: string;
    caption: string;
  }>;
}

export function Photos({ variant, images }: PhotosProps) {
  const randomId = new Date().getTime();

  return (
    <div>
      <div className="mx-auto flex max-w-sm items-center justify-between gap-8">
        <button
          data-prev={randomId}
          className="col-start-5 row-start-2 grid h-fit place-items-center md:col-start-1 md:row-start-1"
          aria-label="Tour anterior"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="#000000"
            viewBox="0 0 256 256"
            className={arrow({ color: variant })}
          >
            <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
          </svg>
        </button>
        <strong className={title({ color: variant, align: "centered" })}>
          Lazer
        </strong>
        <button
          data-next={randomId}
          className="col-start-8 row-start-2 grid h-fit place-items-center md:col-start-12 md:row-start-1"
          aria-label="Próximo tour"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="#000000"
            viewBox="0 0 256 256"
            className={arrow({ color: variant })}
          >
            <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
          </svg>
        </button>
      </div>
      <Fancybox className="mt-8">
        <Swiper
          className="h-[320px] md:h-[400px]"
          modules={[Grid, Navigation]}
          navigation={{
            prevEl: `[data-prev='${randomId}']`,
            nextEl: `[data-next='${randomId}']`,
          }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 5,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
          }}
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <a
                href={`/img/desktop/${item.src}`}
                data-fancybox="leisure"
                className="block h-full w-full"
              >
                <Image
                  className="h-full w-full rounded-2xl object-cover"
                  src={`/img/mobile/${item.src}`}
                  alt={item.caption}
                  width={425}
                  height={425}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </Fancybox>
    </div>
  );
}
