"use client";

import { ReactNode, createContext, useState } from "react";

interface MapContextProviderProps {
  children: ReactNode;
}

interface MapContextProps {
  markedPoints: number[];
  render: boolean;
  togglePoint: (point: number) => void;
  renderMap: () => void;
}

export const MapContext = createContext<MapContextProps>({} as MapContextProps);

export function MapProvider({ children }: MapContextProviderProps) {
  const [markedPoints, setMarkedPoints] = useState<number[]>([0]);
  const [render, setRender] = useState(false);

  function togglePoint(point: number) {
    const isPointMarked = markedPoints.includes(point);

    setMarkedPoints(
      isPointMarked
        ? markedPoints.filter((i) => i != point)
        : [...markedPoints, point],
    );
  }

  function renderMap() {
    setRender(true);
  }

  return (
    <MapContext.Provider
      value={{ togglePoint, markedPoints, render, renderMap }}
    >
      {children}
    </MapContext.Provider>
  );
}
