import { Marker, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import { MapContext } from "@/contexts/MapContext";
import { useContext, useEffect } from "react";
import { map } from "@/mocks/map";

export function Markers() {
  const { markedPoints } = useContext(MapContext);

  const markers = map.filter((_, index) => markedPoints.includes(index));
  const mapRef = useMap();

  useEffect(() => {
    const [lastClickedMarker] = markedPoints.slice(-1);
    const currentMapItem = map[lastClickedMarker];

    if (lastClickedMarker !== undefined && currentMapItem.variant != "nearby") {
      mapRef.setView(currentMapItem.map?.markers[0] as LatLngExpression);
    }
  }, [markedPoints]);

  return (
    <>
      {markers?.map(({ map }) => {
        const icon = new L.Icon({
          iconUrl: `/img/pins/${map.pin}`,
          iconSize: new L.Point(64, 64),
        });

        return map.markers.map((coordinate, j) => {
          return (
            <Marker
              position={coordinate as LatLngExpression}
              icon={icon}
              key={j}
            />
          );
        });
      })}
    </>
  );
}
