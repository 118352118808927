"use client";

import { ReactNode, createContext, useState } from "react";

interface BuildingContextProviderProps {
  children: ReactNode;
}

interface BuildingContextProps {
  currentTab: string | null;
  changeCurrentTab: (newTab: string) => void;
}

export const BuildingContext = createContext<BuildingContextProps>(
  {} as BuildingContextProps,
);

export function BuildingProvider({ children }: BuildingContextProviderProps) {
  const [currentTab, setCurrentTab] = useState<string | null>(null);

  function changeCurrentTab(newTab: string) {
    setCurrentTab(currentTab == newTab ? null : newTab);
  }

  return (
    <BuildingContext.Provider value={{ currentTab, changeCurrentTab }}>
      <div className="group">{children}</div>
    </BuildingContext.Provider>
  );
}
