import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/blue-lake-logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/golden-lake-logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/logo-alt.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/logo-incena.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/logo-internit.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/praia-dos-anjos-logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/video-thumb.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Banner/Video.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Buildings/Building/Gallery/Swiper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Buildings/Building/Menu/Panels/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Buildings/Building/Menu/Tabs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Contact/Form/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Global/PrivacityModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Location/Controls/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Location/Map/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/BuildingContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/MapContext.tsx")