"use client";

import * as z from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useState } from "react";
import { Input } from "@/components/Global/Input";
import { PhoneInput } from "@/components/Global/PhoneInput";
import PrivacityModal from "@/components/Global/PrivacityModal";
import { Variant } from "@/@types/Variant";
import { title } from "./variants";
import axios from "axios";
import { useRouter, useSearchParams } from "next/navigation";
import { toast } from "react-toastify";
import { getBuildingById } from "@/utils/getBuildingById";
import { getAnalytics } from "@/utils/getAnalytics";

interface KnowMoreProps {
  buildingId: string;
  variant: Variant;
}

export function KnowMore({ buildingId, variant }: KnowMoreProps) {
  const router = useRouter();
  const query = useSearchParams();

  const formSchema = z.object({
    name: z.string().min(2),
    email: z.string().email(),
    phone: z
      .string({
        required_error: "Não pode estar vazio",
      })
      .min(10),
  });

  type FormData = z.infer<typeof formSchema>;

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true);

    try {
      await axios.post("/api/send", {
        ...data,
        form: getBuildingById(buildingId),
        productId: buildingId,
        analytics: getAnalytics(query),
      });

      reset({
        name: "",
        email: "",
        phone: "+55",
      });

      router.push("/contato-realizado");
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao enviar o formulário!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-8 xl:px-20">
      <strong className={title({ color: variant, align: "centered" })}>
        Entre em contato
      </strong>
      <form
        className="mt-10 flex flex-col items-center gap-8 font-poppins"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid w-full gap-4 md:grid-cols-3">
          <div className="border border-black/10">
            <label htmlFor="name" className="sr-only">
              Nome
            </label>
            <Input
              id="name"
              {...register("name")}
              data-error={!!errors.name}
              type="text"
              placeholder="Nome"
            />
          </div>

          <div className="border border-black/10">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <Input
              id="email"
              {...register("email")}
              data-error={!!errors.email}
              type="text"
              placeholder="E-mail"
            />
          </div>

          <div className="border border-black/10">
            <label htmlFor="phone" className="sr-only">
              Telefone
            </label>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, ref, ...field } }) => (
                <PhoneInput
                  hasError={!!errors.phone}
                  onChange={(...args) => onChange(args[3])}
                  {...field}
                  inputProps={{
                    ref,
                    id: "phone",
                    inputMode: "numeric",
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="flex w-full flex-col justify-between gap-x-16 gap-y-6 md:flex-row md:items-center">
          <div className="flex gap-2">
            <input
              id="terms"
              type="checkbox"
              className="h-4 w-4 shrink-0"
              checked={isTermsAccepted}
              onChange={(e) => setIsTermsAccepted(e.target.checked)}
            />

            <label htmlFor="terms">
              Eu li e aceito os termos e{" "}
              <PrivacityModal>
                <button
                  className="inline underline underline-offset-2"
                  type="button"
                >
                  Política de Privacidade
                </button>
              </PrivacityModal>
            </label>
          </div>

          <button
            disabled={!isTermsAccepted || isLoading}
            type="submit"
            className="grid h-10 w-32 place-items-center rounded-sm bg-residencial-teal font-medium uppercase text-white transition-all enabled:hover:brightness-95 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <svg
                className="animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#fff"
                viewBox="0 0 256 256"
              >
                <path d="M232,128a104,104,0,0,1-208,0c0-41,23.81-78.36,60.66-95.27a8,8,0,0,1,6.68,14.54C60.15,61.59,40,93.27,40,128a88,88,0,0,0,176,0c0-34.73-20.15-66.41-51.34-80.73a8,8,0,0,1,6.68-14.54C208.19,49.64,232,87,232,128Z"></path>
              </svg>
            ) : (
              "Enviar"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
